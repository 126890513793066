html {
  background-color: black;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.App p {
  color:white;
}

.Homepage {
  color: white;
}

.Wrapper {
  height: 85vh;
  width: 85vw;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 1s ease;
}

.Thumbnails {
  height: 100%;
  width: 10%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  overflow-y: auto;
  transition: all 1s ease;
}

.Thumb {
  border-radius: 0.125rem;
  width: 95%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  flex: 0 0 auto;
}

.Thumb:hover {
  opacity: 60%;
  cursor: pointer;
  transition-timing-function: ease;
  transition: 0.2s;
}

.Thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Imagem {
  border-radius: 0.125rem;
  max-width: 65%;
  width: auto;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
}

.Imagem img {
  height: 100%;
  width: auto;
  object-fit: cover;
  transition: all 1s ease;
}

.Narrativa {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  width: 25%;
  transition: all 1s ease;
}

.Tags {
  display: flex;
  flex-direction: row;
  gap:0.6rem;
  flex-wrap: wrap;
}

.Tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  color:black;
  padding: 0.25rem;
  border-radius: 0.125rem;
  vertical-align: center;
  font-size: 0.85rem;
}

.Data {
  font-size: 20px;
}

.Corpo {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}


::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}


::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 20px;
}

@media (max-width: 1280px){
  .Imagem{
    height: 75%;
  }

  .Thumbnails {
    height: 75%;
    gap: 0.5rem;
  }

  .Narrativa {
    height: 75%;
  }

  .Wrapper {
    gap: 0.825rem;
  }
}

@media (max-width: 1024px){
  .App {
    height: auto;
    max-width: 100vw;
    padding: 2rem 0;
  }

  .Wrapper {
    height: auto;
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
  }

  .Thumbnails {
    height: 10%;
    width: 90%;
    flex-direction: row;
    overflow-x: auto;
  }

  .Thumb {
    width:18%;
  }

  .Imagem {
    border-radius: 0.125rem;
    max-width: 90%;
    width: auto;
    height: auto;
  }

  .Imagem img {
  max-height: 90vh;
  height: auto;
  width: 100%;
  }

  .Narrativa {
    width: 90%;
    gap: 0.5rem;
  }

}